import React from 'react'

import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { makeStyles, createStyles } from '@material-ui/core/styles';


export const theme = createTheme({
    palette: {
        primary: {
            // Homepage #008c58
            // Anita #008D58 
            main: "#008c58",
        },
        secondary: {
            main: grey[300],
        },
        background: {
            main: "#fafafa",
        }
    },
    body: {
        fontWeight: '200',
    },
    typography: {
        h1: {
            fontSize: '2.5rem',
            lineHeight: '120%',
            marginTop: '0.5em',     
            marginBottom: '0.5em',
            fontWeight: '400',
        },
        h2: {
            fontSize: '2.2rem',
            lineHeight: '120%',
            marginTop: '0.5em',     
            marginBottom: '0.5em',
            fontWeight: '400',      
        },
        h3: {
            fontSize: '2.0rem',
            lineHeight: '120%',
            marginTop: '0.5em',     
            marginBottom: '0.5em',
            fontWeight: '400',      
        },
        h4: {
            fontSize: '1.8rem',
            lineHeight: '120%',
            marginTop: '0.5em',     
            marginBottom: '0.5em',
            fontWeight: '400',
        },
        h5: {
            fontSize: '1.6rem',
            lineHeight: '120%',
            marginTop: '0.5em',     
            marginBottom: '0.5em',
            fontWeight: '400',
        },
        h6: {
            fontSize: '1.3rem',
            lineHeight: '120%',
            marginTop: '0.5em',     
            marginBottom: '0.5em',
            fontWeight: '400',
        },
    },
});

const styles = {
    container: {
        align: 'center',
        padding: 5,
        flexWrap: 'wrap',
    },
    appBar: {
        position: 'relative',
    },
    bottomNav: {
        bottom: theme.spacing(0),
        backgroundColor: theme.palette.primary,
        top: 'auto',
        width: '100vw',
        position: 'fixed',
    },
    footer: {
        marginBottom: '8vh',    
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    loadingWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '40%',
        //      marginTop: ,
        //      marginLeft: -12,
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    tableContainer: {
        maxHeight: 400,
    },
    serviceRequestsTableContainer: {
        minHeight: "5vh",
        //maxHeight: "80vh",
    },
    fab: {
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        top: 'auto',
        left: 'auto',
        position: 'fixed',
    },
    tabbedServices:{
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    tabAppBar: {
        marginTop: theme.spacing(3),
    },
    vertTabs: {
	flexGrow: 1,
	backgroundColor: theme.palette.background.paper,
	display: 'flex',
//	width: '20%',	
    },
    vertTabPanel: {
//	width: '80%',
    },
    smallTabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    accordionHeading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        //flexShrink: 0,
    },
    accordionSecondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    tablePaper:{
        marginBottom: theme.spacing(5),
    },
    paper: {
        margin: theme.spacing(2),
        //margin: 'auto',
        marginTop: '1em',
        padding: '1.5em',
        flexGrow: 1,
        flexWrap: 'wrap',
    },
    stepper: {
        margin: theme.spacing(2),
        width: theme.spacing(1),
        display: 'block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 2,
        },
        '& input:invalid + fieldset': {
            borderColor: 'red',
            borderWidth: 2,
        },
    },
    invalidTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        input: {
            borderColor: 'red',
            borderWidth: 1,
        },
    },
    form: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(3),    
        display: 'block',       
    },
    textField: {
        margin: theme.spacing(1),
        width: '100%',
    },    
    buttons: {
        display: 'flex',
        justifyContent: 'flex-center',
        //      paddingBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    cardExpand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    cardExpandOpen: {
        transform: 'rotate(180deg)',
    },
    dialog: {
        width: '80%',
        maxHeight: '80vh',
    },
}

export const useStyles = makeStyles(() => (styles))

export const globalStyles = createStyles(styles)

export const ThemeContext = React.createContext({});

import React from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Snackbar, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import PropTypes from 'prop-types'

Toast.propTypes = {
    idToast: PropTypes.string,    
    severity: PropTypes.string,
    title: PropTypes.string,        
    message: PropTypes.string,
    onClose: PropTypes.func.isRequired,    
}

export default function Toast(props){
    const { idToast, title, message, severity, onClose } = props

    const [toastOpen, setToastOpen] = React.useState(false)
    
    const [toast, setToast] = React.useState({
        open: true,
        title: title,
        message: message || "",
        severity: severity || "info",
    })
    
    const  handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose()
    }

    React.useEffect(() => {
        setToastOpen( (!!message) ? true : false )
    }, [message])
    
    return (
        <>
            { toast && (
                <Snackbar
                    id={ idToast || "toast-message" }
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={toastOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseToast}
                    action={
                        <IconButton                            
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseToast}>
                            <CloseIcon
                                fontSize="small"
                            />
                        </IconButton>
                    }
                >
                    <Alert onClose={handleCloseToast} severity={severity || "info"}>
                        { title && (
                            <AlertTitle>{title}</AlertTitle>
                        )}
                        {message}
                    </Alert>
                </Snackbar>
            )}
        </>
    )
}


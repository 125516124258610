import React from 'react';
import { NavLink } from "react-router-dom";

import slogan from "./../img/Slogan.png"
import logo from "./../img/grewe-Bildmarke-RGB.png"

import { AppBar, Toolbar, Box,
         IconButton, Grid,
         Popover, Tooltip,
       } from '@material-ui/core/';

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import Login from './auth/Login';

import { GlobalContext } from "./ContextProvider"
import { globalStyles } from './shared/Theme';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

class Menubar extends React.Component {

    render(){
        const { classes } = this.props
        return (
            <GlobalContext.Consumer>
                {({ user, logout }) => (
                    <div className={classes.grow}>
                        <AppBar position="absolute" color="transparent" className={classes.appBar}>
                            <Toolbar>
                                <Grid container
                                      spaces={0}
                                      justifyContent="space-between"
                                      alignItems="center"
                                >
                                    <Grid item md={1}>
                                        <NavLink
                                            to="/"
                                            id="logo-home-button"
                                        >
                                            <img src={logo} height={33} alt="Logo Grewe Gruppe" />
                                        </NavLink>
                                    </Grid>
                                    <Grid item md={9}>                                  
                                        <NavLink
                                            to="/"
                                            id="slogan-home-button"
                                        >
                                            <img src={slogan} height={17} alt="Slogan Grewe Gruppe" />
                                        </NavLink>
                                    </Grid>
                                    <Grid item md={1}>
                                        { !(user) && (
                                            <>
                                                <Tooltip title="Klicken Sie hier, um sich anzumelden" placement="bottom">
                                                    <PopupState variant="popover" popupId="login-menu-popup-popover">
                                                        {(popupState) => (
                                                            <>
                                                                <Tooltip title="Klicken Sie hier, um sich anzumelden" placement="bottom">
                                                                    <IconButton
                                                                        variant="contained"
                                                                        color="inherit"
                                                                        id="sign-in-btn"
                                                                        {...bindTrigger(popupState)}
                                                                    >
                                                                        <LockIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                
                                                                <Popover
                                                                    {...bindPopover(popupState)}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                >
                                                                    <Box p={4}>
                                                                        <Login />
                                                                    </Box>
                                                                </Popover>
                                                            </>
                                                        )}
                                                    </PopupState>
                                                </Tooltip>
                                            </>
                                        )}
                                        { user && (
                                            <>
                                                <Tooltip title="Klicken Sie hier, um sich abzumelden" placement="bottom">
                                                    <IconButton onClick={logout}
                                                                id="sign-out-btn"
                                                                edge="end"
                                                                aria-label="sign-out"
                                                                color="inherit"
                                                    >
                                                        <LockOpenIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>                             
                            </Toolbar>
                        </AppBar>
                    </div>
                )}
            </GlobalContext.Consumer>
        )
    }
}

export default withStyles(globalStyles)(Menubar);


Menubar.propTypes = {
    classes: PropTypes.object,
    logo: PropTypes.string,        
    slogan: PropTypes.string,    
};

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { NavLink,  } from "react-router-dom"

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import RestoreIcon from '@material-ui/icons/Restore'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import { globalStyles } from './shared/Theme'
import { GlobalContext } from './ContextProvider'

import PropTypes from 'prop-types';

const useStyles = makeStyles(globalStyles);

export default function BottomMenu(props) {
    const classes = useStyles()

    const { env, user, entity } = React.useContext(GlobalContext)
    
    const [value, setValue] = React.useState(-1)
    const [ref, resetRef] = React.useState(props.newRequestRef.current)
    const [openStats, setStatsOpen] = React.useState(false);
    const anchorStatsRef = React.useRef(null);
    
    const toggleStatsMenu = () => {
        setStatsOpen((prevStatsOpen) => !prevStatsOpen);
    };
    
    const closeStatsMenu = (event) => {
        if (anchorStatsRef.current && anchorStatsRef.current.contains(event.target)) {
            return;
        }
        setStatsOpen(false);
    };
    
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setStatsOpen(false);
        }
    }
    
    const prevStatsOpen = React.useRef(openStats);
    React.useEffect(() => {
        if (prevStatsOpen.current === true && openStats === false) {
            anchorStatsRef.current.focus();
        }
        
        prevStatsOpen.current = openStats;
    }, [openStats]);
    
    
    React.useEffect(() => {
        if(ref)
            ref.resetState()
    },[ref])
    
    
    return (
        <>
            <div>
                <Popper open={openStats} anchorEl={anchorStatsRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={closeStatsMenu}>
                                    <MenuList autoFocusItem={openStats} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem
                                            onClick={closeStatsMenu}
                                            component={NavLink}
                                            to="/stats"
                                        >
                                            Überblick
                                        </MenuItem>
                                        <MenuItem
                                            onClick={closeStatsMenu}
                                            component={NavLink}                           
                                            to="/stats/users"
                                        >
                                            Benutzer
                                        </MenuItem>
                                        <MenuItem
                                            onClick={closeStatsMenu}
                                            component={NavLink}                           
                                            to="/stats/service_requests"
                                        >
                                            Anfragen
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                className={ classes.bottomNav }
            >
                { (user && user.is_admin) && (
                    <BottomNavigationAction
                        label="Statistiken"
                        icon={<MenuIcon />}
                        id="stats-btn"
                        component={ NavLink }
                        to="/stats"
                    />
                )}
                { entity && entity.features_enabled && entity.features_enabled.includes("serviceRequestsSearch") && user && user.is_employee && (
                    <BottomNavigationAction
                        label="Suche"
                        icon={<SearchIcon />}
                        id="search-request-btn"
                        component={ NavLink }
                        to="/suche"
                    />
                )}
                
                {user && (
                    <BottomNavigationAction
                        label="Anfragen"
                        icon={<RestoreIcon />}
                        id="request-history-btn"
                        component={ NavLink }
                        to="/anfragen_nach_status"
                    />
                )}
                <BottomNavigationAction
                    onClick={ () => resetRef(props.newRequestRef.current) }
                    label="Neue Anfrage"
                    algin="center"
                    icon={<AddIcon />}
                    id="new-request-btn"
                    component={NavLink}
                    to="/neu"
                />
                { user && (
                    <BottomNavigationAction
                        label="Konto"
                        icon={<PersonIcon/>}
                        id="my-account-btn"
                        component={ NavLink }
                        to="/konto"
                    />
                )}
                { (user && user.is_admin) && (
                    <BottomNavigationAction
                        label="Benutzerverwaltung"
                        icon={<PeopleIcon/>}
                        id="user-admin-btn"
                        component={ NavLink }
                        to="/benutzerverwaltung"
                    />
                )}
            </BottomNavigation>
        </>
        
    );
}

BottomMenu.propTypes = {
    user: PropTypes.object,
    newRequestRef: PropTypes.object,
}

import React from 'react'
import {
    Backdrop, CircularProgress,
    Grid, Typography,
}
from '@material-ui/core';
import { globalStyles } from './Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class LoadingBackdrop extends React.Component{

    render(){
        const { classes } = this.props 
        return(
            <Backdrop className={classes.backdrop} open={true} >
                <Grid container align="center" spacing={2}>
                    <Grid item xs={12}>
                        <CircularProgress color="inherit" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center">
                            {this.props.message}
                        </Typography>
                    </Grid>
                </Grid>
            </Backdrop>
        )
    }
}

export default withStyles(globalStyles)(LoadingBackdrop);

LoadingBackdrop.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string.isRequired,    
}



import axios from 'axios'

const setAxiosHeaders = () => {
    const token = localStorage.getItem('auth-token') || ''
    if('' !== token){
	axios.defaults.headers.common['Authorization'] = "Bearer " + token
    }
    /*
    else{
	console.log("no token found in local storage")
    }
    */
    const csrfToken = document.querySelector('[name=csrf-token]')
    if (!csrfToken) {
	return
    }
    const csrfTokenContent = csrfToken.content
    csrfTokenContent && (
	axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfTokenContent
    )
    axios.defaults.headers.common['Accept'] = 'application/json'
    axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
}

export default setAxiosHeaders

import React from 'react'

import { globalStyles } from './Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class TabPanel extends React.Component {
    
    render(){
        return (
            <div
                role="tabpanel"
                hidden={this.props.value !== this.props.index}
                id={`${this.props.desc}-${this.props.index}`}
                aria-labelledby={`tabpanel-${this.props.index}`}
                {...this.props}
            >
                {this.props.value === this.props.index && (
                    <>
                        { this.props.children }
                    </>
                )}
            </div>
        )
    }
}               

export default withStyles(globalStyles)(TabPanel);


TabPanel.propTypes = {
    value: PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number
    ]),
    index: PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number
    ]),
    desc: PropTypes.string,
    children:  PropTypes.any,
};

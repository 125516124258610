import React from "react";

import { Route, Redirect,  } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import {globalStyles } from './shared/Theme';
import PropTypes from 'prop-types';

class AdminRoute extends React.Component{

    render(){
        if(this.props.user && this.props.user.is_admin){
            return <Route {...this.props} />
        }
        return <Redirect to="/" /> 
    }
}

export default withStyles(globalStyles)(AdminRoute);

AdminRoute.propTypes = {
    user: PropTypes.object,
}

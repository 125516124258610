import React from "react"

import {
    Grid, Paper, Typography, Link
} from '@material-ui/core/';

import LoginForm from './auth/LoginForm'
import RegisterForm from './auth/RegisterForm'

import { GlobalContext } from './ContextProvider';
import { globalStyles } from './shared/Theme';
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types';

class Welcome extends React.Component{

    render(){
        const { classes } = this.props
        return (
            <>
                <GlobalContext.Consumer>
                    {({ user }) => (
                        <Grid
                            justifyContent="center"
                            //alignItems="center"
                            container
                            spacing={2}
                        >
                        <>
                                <Grid item xs={11} md={5}>
                                    <Paper className={classes.paper}
                                    >
                                        <Typography variant="h1" component="h1">
                                            { user && (
                                                <>
                                                    Herzlich Willkommen {' '}{ user.firstname }{' '}{ user.lastname }!
                                                </>
                                            )}
                                            { !(user) && (
                                                <>
                                                    Herzlich Willkommen im grewe-Kundenportal!
                                                </>
                                            )}
                                        </Typography>
                                        <Typography>
                                            Um Ihnen den Kontakt zu uns so komfortabel wie möglich zu gestalten, haben wir das Kundenportal entwickelt – der einfache Weg zu Ihrem persönlichen Ansprechpartner innerhalb der grewe-gruppe. 
                                        </Typography>
                                        &nbsp;
                                        <Typography>
                                            Telefonisch niemand erreichbar? Ansprechpartner nicht am Platz? Kein Problem!
                                        </Typography>
                                        &nbsp;                                  
                                        <Typography>
                                            Vereinbaren Sie zu jeder Tageszeit einen Rückruf – genau dann, wenn es Ihnen am besten passt. Der für Sie optimale Ansprechpartner ruft Sie zur vereinbarten Zeit zurück und steht Ihnen voll und ganz zu Verfügung. Ihre Wünsche kommen so bei uns an, garantiert!
                                        </Typography>
                                        &nbsp;                                  
                                        <Typography>
                                            Unser Portal ist neu und befindet sich in der Entwicklungsphase. Sollte Ihnen etwas auffallen, was wir noch besser machen können, freuen wir uns auf Ihr Feedback unter <Link href="mailto:info@grewe-gruppe.de">info@grewe-gruppe.de</Link>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                
                                { (!(user)) && (
                                    <>
                                        <Grid item xs={11} md={5}>
                                            <Paper className={classes.paper}>
                                                <Typography variant="h2" component="h2">
                                                    Anmelden
                                                </Typography>
                                                <LoginForm />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={11} md={5}>
                                            <RegisterForm />
                                        </Grid>

                                    </>
                                )}
                                <Grid item xs={11} md={5}>
                                    <Paper className={classes.paper}>                       
                                        <Typography variant="h2" component="h2">
                                            Erstellen Sie eine Rückrufbitte
                                        </Typography>
                                        <Typography>
                                            So funktioniert’s: Klicken Sie auf das grüne Plus-Icon in der Mitte unten und vereinbaren einen Rückruf
                                            zu einer für Sie passenden Zeit. Wählen Sie vorab aus, welche Leistungen wir für Sie durchführen sollen.
                                            Ihr persönlicher Ansprechpartner ruft Sie zur vereinbarten Zeit zurück und beantwortet Ihre Fragen.
                                        </Typography>
                                    </Paper>
                                </Grid>
                                
                            </>
                        </Grid>
                    )}
                </GlobalContext.Consumer>
            </> 
        )
    }
}

export default withStyles(globalStyles)(Welcome);



Welcome.propTypes = {
    classes: PropTypes.object,
};

import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import { globalStyles } from './Theme'
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            errorInfo: null,
        }
    }
    
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }
    
    render() {
        if(this.state.errorInfo) {
            return (
                <div>
                    <h2>Da ist etwas schief gelaufen.</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        return this.props.children;
    }  
}

export default withStyles(globalStyles)(ErrorBoundary);

ErrorBoundary.propTypes = {
    children: PropTypes.any,
}

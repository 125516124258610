import React from "react";

import { Redirect } from "react-router-dom";

import axios from "axios";
import setAxiosHeaders from "../shared/AxiosHeaders";

import { Alert, AlertTitle } from '@material-ui/lab';
import { Grid, TextField, FormControl, Button,
         CircularProgress, } from '@material-ui/core/';

import { GlobalContext } from '../ContextProvider'
import { globalStyles } from '../shared/Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class LoginForm extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            curTab: 0,
            email: undefined,       
            password: undefined,
            loginSuccess: false,            
            errorHash: {},
            emailHelperText: null,
            passwordHelperText: null,
            submitted: false,
        }
        this.formValid = this.formValid.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.keyPress = this.keyPress.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }
    
    submitForm(event, afterLoginCallback){
        this.setState({
            submitted: true,
            emailHelperText: null,
            passwordHelperText: null,
            errorHash: {},
            loginError: false,
            loginSuccess: false,
        })
        if(event !== undefined)
            event.preventDefault()
        //console.log("sending user... with " + this.state.email + ":" + this.state.password)
        const user = {
            email: this.state.email,
            password: this.state.password,
        }
        setAxiosHeaders();
        axios.post('/api/v1/auth', {        
            user: JSON.stringify(user)
        })
            .then(response => {
                //console.log("status " + response.data['token'])
                localStorage.setItem('auth-token', response.data['token'])
                this.setState({
                    loginSuccess: true,
                })
                afterLoginCallback()
                return true
            })
            .catch(error => {
                console.log("error: " + error)
                console.log("error.response: " + error.response)
                if(error.response)
                {
                    if(401 === error.response.status){
                        this.setState({
                            emailHelperText: "Ist Ihre E-Mail Adresse korrekt?",
                            passwordHelperText: "Ist Ihr Passwort richtig?",
                            errorHash: {
                                severity: "error",
                                title: "Zugangsdaten nicht korrekt",
                                message: "E-Mail oder Passwort ungültig.",
                            },
                            loginError: true,
                            submitted: false,
                        })
                        return false
                    }
                    if(500 === error.response.status){
                        this.setState({
                            errorHash: {
                                severity: "error",
                                title: "Serverfehler",
                                message: "Die Kommunikation mit dem Server ist fehlerhaft. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
                            },

                            submitted: false,
                        })
                        return false
                    }
                    if(502 === error.response.status){
                        this.setState({
                            errorHash: {
                                severity: "error",
                                title: "Portal nicht verfügbar",
                                message: "Das Portal wird zur Zeit aktualisiert und steht für einige Minuten nicht zur Verfügung",
                            },
                            submitted: false,               
                        })
                        return false
                    }
                    this.setState({
                        errorHash: {
                            severity: "error",
                            title: "Server nicht erreichbar",
                            message: "Die Kommunikation mit dem Server ist aktuell nicht möglich oder fehlerhaft. Bitte versuchen Sie es in einigen Minuten erneut",
                        },
                        submitted: false,               
                    })
                    return false
                }
                
                this.setState({
                    errorHash: {
                        severity: "error",
                        title: "Server nicht erreichbar",
                        message: "Die Kommunikation mit dem Server ist aktuell nicht möglich oder fehlerhaft. Bitte versuchen Sie es in einigen Minuten erneut",
                    },
                    submitted: false,               
                })  
                return false
            })
    }

    componentDidMount(){
    }

    formValid(){
        return !!(this.state.email && this.state.password)
    }

    changeHandler(){
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    keyPress(event, afterLoginCallback){
        if(event.key === "Enter")
            this.submitForm(undefined, afterLoginCallback)
    }

    render(){
        const {classes} = this.props
        return(
            <>
                { ( this.state.loginSuccess && this.props.redirectPath) && (
                    <Redirect to={this.props.redirectPath} />
                )}
                <GlobalContext.Consumer>
                    { ( { afterLoginCallback  } ) => (
                        <Grid container spacing={2}>
                            <FormControl className={classes.form} onKeyPress={(e) => this.keyPress(e, afterLoginCallback)}>
                                { (this.state.errorHash && Object.keys(this.state.errorHash)).length !== 0 && (
                                    <Alert severity={this.state.errorHash.severity}>
                                        <AlertTitle>{this.state.errorHash.title}</AlertTitle>
                                        {this.state.errorHash.message}
                                    </Alert>
                                )}
                                <Grid item>
                                    <TextField
                                        label="E-Mail"
                                        name="email"
                                        id="login_user_email"
                                        onChange={this.changeHandler}
                                        className={classes.textField}
                                        fullWidth={true}
                                        required
                                        error={this.state.loginError}
                                        helperText={this.state.emailHelperText}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Passwort"
                                        type="password"
                                        name="password"
                                        id="login_user_password"
                                        className={classes.textField}
                                        onChange={this.changeHandler}
                                        fullWidth={true}
                                        required
                                        error={this.state.loginError}                                   
                                        helperText={this.state.passwordHelperText}                  
                                    />
                                </Grid>
                                <Grid item>
                                    <div className={classes.loadingRoot}>
                                        <div className={classes.loadingWrapper}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                id="user_login_button"
                                                size="small"
                                                className={classes.button}
                                                onClick={ (event) => this.submitForm(event, afterLoginCallback) }
                                                disabled={false == this.formValid() || this.state.submitted}
                                            >
                                                Anmelden
                                            </Button>
                                            {this.state.submitted && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>
                                    </div>
                                </Grid>                                                                     
                            </FormControl>
                        </Grid>
                    )}
                </GlobalContext.Consumer>
            </>
        )
        
    }
}

export default withStyles(globalStyles)(LoginForm);

LoginForm.propTypes = {
    classes: PropTypes.object,
    redirectPath: PropTypes.string,
};

import React from "react";

import axios from "axios";
import setAxiosHeaders from "../shared/AxiosHeaders";

import { Alert } from '@material-ui/lab';
import {
    Grid, TextField, FormControl, Button
} from '@material-ui/core/';

import { globalStyles } from '../shared/Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class RequestNewPasswordForm extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            resetMailAddr: undefined,
            resetMailAddrError: false,
            resetMailAddrSuccess: false,                    
            resetMailAddrSubmitted: false,
            
        }
        this.submitResendMail = this.submitResendMail.bind(this)
        this.resetMailAddrChange = this.resetMailAddrChange.bind(this)
        this.submitResendMail = this.submitResendMail.bind(this)
    }
    
    submitResendMail(event){
        event.preventDefault();
        this.setState({
            resetMailAddrSubmitted: true,
            resetMailAddrSuccess: false,
            resetMailAddrError: false,
        })
        const user = {
            resetMailAddr: this.state.resetMailAddr,
        }
        setAxiosHeaders();
        axios.post('/api/v1/reset_password', {      
            user: JSON.stringify(user)
        })
            .then(() => {
                this.setState({
                    resetMailAddrSuccess: true,
                })
                return true
            })
            .catch(() => {
                this.setState({
                    resetMailAddrError: true,
                })
                return false
            })
    }

    resetMailAddrChange(event){
        this.setState({
            resetMailAddr: event.target.value
        })
    }

    render(){
        const {classes} = this.props
        return(
            <Grid container spacing={2}>
                { this.state.resetMailAddrSuccess && (
                    <Grid item>
                        <Alert severity="success">
                            Bitte überprüfen Sie Ihre E-Mails.
                            Wenn wir Ihre E-Mail Adresse in der Datenbank gespeichert haben,
                            dann haben Sie nun eine E-Mail erhalten.
                        </Alert>
                    </Grid>
                )}
                { this.state.resetMailAddrError && (
                    <Grid item>
                        <Alert severity="error">
                            Es ist ein interner Fehler aufgetreten.
                        </Alert>
                    </Grid>
                )}
                { !(this.state.resetMailAddrSuccess) && (
                    <FormControl className={classes.form}>
                        <Grid item>
                            <TextField
                                label="E-Mail"
                                name="reset_email"
                                id="reset_user_email"
                                onChange={this.resetMailAddrChange}
                                className={classes.textField}
                                fullWidth={true}
                                required
                                error={this.state.loginError}
                            />
                        </Grid>
                        <Grid item>                             
                            <Button
                                variant="outlined"
                                color="primary"
                                id="user_login_button"
                                size="small"
                                className={classes.button}
                                onClick={ (event) => this.submitResendMail(event) }
                                disabled={this.state.resetMailAddrSubmitted}
                            >
                                Senden
                            </Button>
                        </Grid>                                                                     
                    </FormControl>
                )}
            </Grid>
        )
    }
}

export default withStyles(globalStyles)(RequestNewPasswordForm);

RequestNewPasswordForm.propTypes = {
    classes: PropTypes.object,    
};

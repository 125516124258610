import React from 'react'
import { GlobalContext } from './ContextProvider'
import MUICookieConsent from 'material-ui-cookie-consent';
import { globalStyles } from './shared/Theme';
import { withStyles } from '@material-ui/core/styles';

import {
    Grid, Link, Typography,
} from '@material-ui/core/';

import PropTypes from 'prop-types';


class Footer extends React.Component {

    render(){
        const {classes} = this.props
        return(
            <>
                <GlobalContext.Consumer>
                    {( { user, userEmail, userFirstname, userLastname, userID, entity } ) => (
                        <>
                            <Grid container spacing={2} className={classes.footer}>
                                <Grid item xs={12}>                     
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        <Link color="inherit" href="https://www.grewe-gruppe.de/impressum">
                                            Impressum
                                        </Link>
                                        {' '}|{' '}
                                        <Link color="inherit" underline="hover" href="https://www.grewe-gruppe.de/datenschutz">
                                            Datenschutz
                                        </Link>
                                        {' '}|{' '}
                                        <Link color="inherit" href="https://www.grewe-gruppe.de/standorte-kontakt/">
                                            Kontakt
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        {'© '}
                                        {new Date().getFullYear()}
                                        {' '}
                                        <Link color="inherit" href="https://www.grewe-gruppe.de/">
                                            ein Angebot der grewe-gruppe.
                                        </Link>
                                    </Typography>
                                </Grid>
                                { ( this.props.env !== "production" && this.props.env !== "preprod") && (
                                    <Grid item xs={12}>
                                        <Typography variant="body2" color="textSecondary" align="center">
                                            env: { this.props.env } <br />
                                            { user && (
                                                <>
                                                    user id: {userID}<br />
                                                    user: { userFirstname }{" "}{userLastname} ({ userEmail })<br />
                                                    {user && user.is_admin && (
                                                        <>user is admin<br /></>
                                                    )}
                                                    {user && user.is_client && (
                                                        <>user is client<br /></>
                                                    )}
                                                    {user && user.is_employee && (
                                                        <>user is employee<br />
                                                            { user.is_assistant && (
                                                                <>and assistant <br /></>
                                                            )}
                                                            { user.is_site_manager && (
                                                                <>and site manager<br /></>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {false === !!(user) && (
                                                <>
                                                    user not logged in<br />
                                                </>
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <MUICookieConsent 
                                cookieName="GreweCookieConsentTest1"
                                message="Um unsere Dienste anbieten zu können, verwenden und benötigen wir Cookies."
                                acceptButtonLabel="Zustimmen"
                                expires={90}
                                color="primary"
                            />
                        </>
                    )}
                </GlobalContext.Consumer>
            </>
    )
}
}
export default withStyles(globalStyles)(Footer);

Footer.propTypes = {
    env: PropTypes.string,
    classes: PropTypes.object,
};

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom'

import { lazyWithRetry } from './func/LazyWithRetry'

import { Alert, AlertTitle } from '@material-ui/lab';
import { BrowserRouter, Route, Switch,  } from "react-router-dom";

import { CssBaseline, Container, } from '@material-ui/core';

import { debounce } from "lodash"
import axios from "axios";
import setAxiosHeaders from "./components/shared/AxiosHeaders"

import Toast from './components/shared/Toast'
import ErrorBoundary from "./components/shared/ErrorBoundary"

import AdminRoute from     "./components/AdminRoute"
import Welcome from     "./components/Welcome"
import Menubar from "./components/Menubar"
import LoadingBackdrop from  "./components/shared/LoadingBackdrop"
import Spinner from  "./components/shared/Spinner"
import BottomMenu from './components/BottomMenu'
import Footer from "./components/Footer"
import { GlobalContext } from './components/ContextProvider'

import { ThemeProvider } from '@material-ui/styles'
import { withStyles } from '@material-ui/core/styles'
import {ThemeContext, theme, globalStyles } from './components/shared/Theme'
import PropTypes from 'prop-types';


const UserAdministration = lazyWithRetry(() => import("./components/UserAdministration"))
const MyAccount = lazyWithRetry(() => import("./components/MyAccount"))
const NewRequest = lazyWithRetry(() => import("./components/NewRequest"))
const ServiceRequest = lazyWithRetry(() => import("./components/service_request/ServiceRequest"))
const EntityConfig = lazyWithRetry(() => import("./components/EntityConfig"))
//const AdminRoute = lazyWithRetry(() =>  import("./components/AdminRoute"))
const About = lazyWithRetry(() =>  import("./components/About"))
const Version = lazyWithRetry(() =>  import("./components/Version"))
const Playground = lazyWithRetry(() =>  import("./components/Playground"))
const RequestHistory = lazyWithRetry(() =>  import("./components/RequestHistory"))
const Statistics = lazyWithRetry(() =>  import("./components/stats/Statistics"))
const ResetPasswordForm = lazyWithRetry(() => import("./components/auth/ResetPasswordForm"))


class TimberApp extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            isLoading: true,
            env: undefined,
            translations: null,
            leftMenuOpen: false,
            // -- user data
            user: null,
            userID: null,
            user_company: '',
            user_firstname: '',
            user_lastname: '',
            user_street: '',
            user_zip: '',
            user_city: '',
            user_phone: '',
            user_email: '',
            // -- domain config data
            entity: null,
            toast: {},
        }
        this.state.getUser = this.getUser.bind(this)
        this.getUser = this.getUser.bind(this)
        this.getDomainConfig = this.getDomainConfig.bind(this)
        this.state.updateUser = this.updateUser.bind(this)
        this.state.flushUpdateUser = this.flushUpdateUser.bind(this)    
        this.state.afterLoginCallback = this.afterLoginCallback.bind(this)
        this.state.logout = this.logout.bind(this)      
        this.newRequestRef = React.createRef()
        this.state.toText = this.toText.bind(this)
        this.updateUserRemote = debounce(this.updateUserRemote, 3000)
        
        // disable all logging in production
        //if(this.props.env === 'development'){
        if(this.props.env === 'production'){
            console.log = function() {}
        }
    }

    

    componentDidMount() {
        axios.defaults.baseURL = this.backendURL()
        console.log("using " + this.backendURL() + " as backend URL")
        this.getTranslations()
        this.getDomainConfig()
        this.setState({
            env: this.props.env,
        })
        this.state.getUser()
    }

    backendURL(){
        if(window.location.hostname.includes(".lan") || window.location.hostname.includes("localhost")){
            return "http://portal.grewe-gruppe.lan:4001"
        }
        return "https://timbertime-be.elsbroek.com"
    }

    toText(val){
        if(undefined === val){
            this.setState({
                toast: {
                    title: "Darstellungsanweisung nicht gefunden",
                    message: "Einige Darstellungsanweisungen konnten nicht geladen werden. Die Darstellung in der Anwendung könnte fehlerhaft sein.",
                    severity: "warn",
                }
            })
            return
        }
        if(undefined === this.state.translations){
            this.setState({
                toast: {
                    title: "Fehler beim Laden von Serverdaten",
                    message: "Einige Darstellungsanweisungen konnten nicht geladen werden. Die Darstellung in der Anwendung könnte fehlerhaft sein.",
                    severity: "warn",
                }
            })
            return "... laden ..." // emtpy string
        }
        return this.state.translations[val]
    }

    afterLoginCallback(){
        /**
        this.setState({
            toast: {
                message: "Sie haben sich erfolgreich angemeldet.",
                severity: "success",
            }
        })
**/
        this.state.getUser()
    }

    getTranslations(){
        axios.get("/api/v1/translations")
            .then(response => {
                const res = response.data;
                this.setState({
                    translations: res,
                    isLoading: false,
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isLoading: false,                    
                    toast: {
                        title: "Konnte Darstellungsanweisung nicht laden",
                        message: "Einige Darstellungsanweisungen konnten nicht geladen werden. Die Darstellung in der Anwendung könnte fehlerhaft sein.",
                        severity: "error",
                    }
                })
                this.setState({ translations: undefined })
            })
    }

    getDomainConfig(){
        setAxiosHeaders()
        //        console.log("current hostname " + window.location.hostname)
        axios.get("/api/v1/entities/" + window.location.hostname + (window.location.port ? ':' + window.location.port : ''))
            .then(response => {
                const res = response.data;
                this.setState({
                    entity: res,
                    configErrorMessage: undefined,
                })
            })
            .catch(error => {
                if(error.response){
                    switch(error.response.status){
                    case 500:
                        this.setState({
                            config: undefined,
                            configErrorMessageTitle: "Serverfehler!",
                            configErrorMessage: "Es ist ein Fehler bei der Datenverarbeitung im Server aufgetreten. Der Status Code lautet 500."
                        })
                        break
                        // FIXME: should also cover 502 at least...
                    default: 
                        this.setState({
                            config: undefined,
                            configErrorMessageTitle: "Konnte Konfiguration nicht laden",
                            configErrorMessage: "Es ist ein Fehler bei der Kommunikation mit dem Server aufgetreten. Der Fehler lautet wie folgt: " + error.response.status + ": " + JSON.stringify(error.response.data)
                        })
                    }
                    return
                }
                this.setState({
                    config: undefined,
                    configErrorTitle: "Wir konnnte Konfiguration nicht laden.",
                    configErrorMessage: "Fehler bei der Kommunikation mit dem Server. Evtl. ist die Verbindung unterbrochen.",
                })
            })
    }

    logout(){
        this.setState({isLoading: true})
        localStorage.removeItem('auth-token')
        setAxiosHeaders();
        axios.delete("/api/v1/auth")
            .then(response => {
                this.setState({
                    user: undefined,
                    isLoading: false,
                    toast: {
                        //title: "Abgemeldet",
                        message: "Sie haben sich erfolgreich abgemeldet.",
                        severity: "info",
                    }
                })
            })
            .catch(error => {
                console.log("could not log out: " + error);
                if (error.response) {
                    console.log("status: " + error.response.status)
                    console.log("headers: " + JSON.stringify(error.response.headers))
                    console.log("data: " + JSON.stringify(error.response.data))
                    
                }
                if(error.response && error.response.status == 401){
                    /*
                     * session has already expired
                     */
                    this.setState({
                        user: undefined,
                        isLoading: false,
                        toast: {
                            //title: "Abgemeldet",
                            message: "Sie haben sich erfolgreich abgemeldet.",
                            severity: "info",
                        }
                    })
                }
                else{
                    this.setState({
                        toast: {
                            title: "Konnte Abmeldung nicht durchführen",
                            message: "Der Server konnte die Abmeldung nicht korrekt verarbeiten.",
                            severity: "error",
                        }
                    })
                }
                this.setState({isLoading: false})
            })
    }
    
    getUser(){
        if(!(localStorage.getItem('auth-token'))){
            //console.log("no auth token available")
            return
        }
        setAxiosHeaders();
        axios.get("/api/v1/current_user")
            .then(response => {
                const res = response.data;
                //console.log("fetched user with id: " + res.id)
                this.setState({
                    user: res,
                    userID: res.id,
                    userCompany: res.company,              
                    userFirstname: res.firstname,
                    userLastname: res.lastname,
                    userStreet: res.street,
                    userZip: res.zip,
                    userCity: res.city,
                    userPhone: res.phone,
                    userEmail: res.email,
                    warnMessage: undefined,
                    errorMessage: undefined,
                    
                })
            })
            .catch(error => {
                if(error.response){
                    if(error.response.status === 401){
                        localStorage.removeItem('auth-token')           
                        this.setState({
                            user: undefined,
                            warnMessageTitle: "Sitzung abgelaufen",
                            warnMessage: "Ihre Sitzung ist nicht mehr gültig. Bitte melden Sie sich erneut an. Sie werden aus Sicherheitsgründen alle 12 Stunden abgemeldet.",
                        })
                        return
                    }
                    // FIXME: should also cover 500 & 502 at least!
                    localStorage.removeItem('auth-token')           
                    this.setState({
                        user: undefined,
                        errorMessageTitle: "Konnte Daten nicht laden",
                        errorMessage: "Es ist ein Fehler bei der Kommunikation mit dem Server aufgetreten. Der Fehler lautet wie folgt: " + error.response.status + ": " + JSON.stringify(error.response.data)
                    })
                    return
                }
                localStorage.removeItem('auth-token')           
                this.setState({
                    user: undefined,
                    errorTitle: "Wir konnnte Ihre persönlichen Daten nicht laden.",
                    errorMessage: "Fehler bei der Kommunikation mit dem Server. Evtl. ist die Verbindung unterbrochen.",
                })
                
            })
    }

    updateUser(company, firstname, lastname, street, zip, city, phone){
        //console.log("TimberApp.updateUser -> " + firstname +" | "+ lastname +" | "+  street +" | "+  zip +" | "+  city +" | "+  phone)
        this.setState({
            userCompany: company,               
            userFirstname: firstname,
            userLastname: lastname,
            userStreet: street,
            userZip: zip,
            userCity: city,
            userPhone: phone,
        })
        this.updateUserRemote()
    }

    flushUpdateUser(){
        this.updateUserRemote.flush()
    }

    updateUserRemote(){
        const user = {
            company: this.state.userCompany,
            firstname: this.state.userFirstname,
            lastname: this.state.userLastname,
            street: this.state.userStreet,
            zip: this.state.userZip,
            city: this.state.userCity,
            phone: this.state.userPhone,
        }
        setAxiosHeaders();
        axios.post('/api/v1/users/' + this.state.userID, {
            user: user,
        })
            .then(response => {
                this.setState({
                    toast: {
                        severity: "success",
                        message: "Benutzerdaten aktualisiert"
                    }
                })
                return
            })
            .catch(error => {
                // FIXME: should cover all other cases also
                this.setState({
                    toast: {
                        severity: "error",
                        message: "Benutzerdaten konnten nicht aktualisiert werden"
                    }
                })
                return
            })
    }

    render(){
        const classes = withStyles(theme)
        //console.log("rendering and user is " + JSON.stringify(this.state.user))
        return (
            <ErrorBoundary>
                <ThemeProvider theme={ theme }>
                    <GlobalContext.Provider value={ this.state }>
                        <BrowserRouter>
                            <>
                                <Container className={classes.container}>
                                    <CssBaseline />
                                    <Menubar
                                        logout={this.logout}
                                        logo={this.props.logo}
                                        slogan={this.props.slogan}                          
                                        user={this.state.user}
                                    />
                                    { this.state.isLoading && (
                                        <>
                                            <LoadingBackdrop message="Lade Startseite" />
                                        </>
                                    )}
                                    { this.state.toast && (
                                        <Toast
                                            title={this.state.toast.title}
                                            message={this.state.toast.message}
                                            severity={this.state.toast.severity}
                                            onClose={() => { this.setState({toast: {}}) }}
                                        />
                                    )}
                                    { (this.state.warnMessage) && (
                                        <Alert severity="warning" onClose={() => {this.setState({warnMessage: undefined,})}}>
                                            <AlertTitle>{this.state.warnMessageTitle}</AlertTitle>
                                            {this.state.warnMessage}
                                        </Alert>
                                    )}
                                    { (this.state.configErrorMessage) && (
                                        <Alert severity="error" onClose={() => {this.setState({configErrorMessage: undefined,})}}>
                                            <AlertTitle>{this.state.configErrorMessageTitle}</AlertTitle>
                                            {this.state.configErrorMessage}
                                        </Alert>
                                    )}

                                    { (this.state.errorMessage) && (
                                        <Alert severity="error" onClose={() => {this.setState({errorMessage: undefined,})}}>
                                            <AlertTitle>{this.state.errorMessageTitle}</AlertTitle>
                                            {this.state.errorMessage}
                                        </Alert>
                                    )}
                                    { (this.state.successMessage) && (
                                        <Alert severity="success" onClose={() => {this.setState({successMessage: undefined,})}}>
                                            <AlertTitle>{this.state.successMessageTitle}</AlertTitle>
                                            {this.state.successMessage}
                                        </Alert>
                                    )}
                                    { (!this.state.user) && (
                                        <Alert severity="info">
                                            <AlertTitle>Sie benutzen unser Portal als Gast</AlertTitle>
                                            Aktuell sind Sie nicht angemeldet.
                                            Sie können das Portal ohne Einschränkungen verwenden,
                                            jedoch nicht später Ihre Anfragen hier im Portal einsehen.
                                            Als registrierter Benutzer können Sie auch Ihre Daten hinterlegen und
                                            brauchen Sie so nicht bei jeder Anfrage erneut eingeben. 
                                            Registrieren Sie sich{' '}
                                            <a onClick={(event) => event.stopPropagation()} href="/#register-new">hier</a>.
                                        </Alert>
                                    )}
                                    { (false === this.state.isLoading) && (
                                        <Switch>
                                            {/* Admin Routes */}
                                            <AdminRoute
                                                user={this.state.user}
                                                path="/stats"
                                            >
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <Statistics />                                    
                                                </Suspense>
                                            </AdminRoute>
                                            {/* Everyones Routes */}
                                            <Route path="/passwort/neu">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <ResetPasswordForm />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/neu">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <NewRequest
                                                        env={this.state.env}
                                                        getUser={this.getUser}
                                                        handleErrors={this.handleErrors}
                                                        clearErrors={this.clearErrors}
                                                        user={this.state.user}
                                                        translations={this.state.translations}
                                                        ref={this.newRequestRef}
                                                        entity={this.state.entity}
                                                    />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/anfragen/:id">
                                                <Suspense fallback={<Spinner message="Lade Anfrage..." />}>
                                                    <ServiceRequest
                                                        env={this.state.env}
                                                        entity={this.state.entity}
                                                    />
                                                </Suspense>                         
                                            </Route>
                                            
                                            <Route path="/anfragen_nach_status">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                  <RequestHistory
                                                    entity={this.state.entity}
                                                    user={this.state.user}
                                                  />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/konto">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <MyAccount
                                                        env={this.props.env}
                                                    />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/benutzerverwaltung">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <UserAdministration />
                                                </Suspense>                                         
                                            </Route>
                                            <Route path="/playground">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <Playground />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/version">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <Version />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/about">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <About />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/config">
                                                <Suspense fallback={<Spinner message="Lade Inhalte" />}>
                                                    <EntityConfig
                                                        user={this.state.user}
                                                    />
                                                </Suspense>                         
                                            </Route>
                                            <Route path="/">
                                                <Welcome />
                                            </Route>
                                        </Switch>
                                    )}
                                    <Footer
                                        className={classes.footer}
                                        env={this.state.env}
                                    />
                                </Container>
                                <BottomMenu
                                    user={this.state.user}                              
                                    newRequestRef={this.newRequestRef}
                                    onSidebarOpen={this.toggleLeftMenu}
                                />
                            </>
                        </BrowserRouter>
                    </GlobalContext.Provider>
                </ThemeProvider>
            </ErrorBoundary>
        )}
}

export default withStyles(globalStyles)(TimberApp);

TimberApp.contextType = ThemeContext;

document.addEventListener('turbolinks:load', () => {
    const app = document.getElementById('timber-app')
    app && ReactDOM.render(
        <TimberApp
            env={app.getAttribute('data-env')}
            logo={app.getAttribute('data-logo')}
            slogan={app.getAttribute('data-slogan')}        
        />, app)
})

TimberApp.propTypes = {
    env: PropTypes.string,            
    logo: PropTypes.string,        
    slogan: PropTypes.string,    
};


import React from 'react'

export const GlobalContext = React.createContext()

export const ChooseServiceContext = React.createContext()

export const ServiceRequestContext = React.createContext({
      lmSquaremeters: null,
      chHeigth: null,
      chLength: null,      
      chThickness: null,
      chValid: false,
    updateLawnMowingSqm(){},
})

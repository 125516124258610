import React from "react";
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { globalStyles } from './Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class Spinner extends React.Component {
    render(){
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={8}                             
            >
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {this.props.message}
                    </Typography>
                </Grid>             
                <Grid item xs={12}>                 
                    <Typography>
                        &nbsp;
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(globalStyles)(Spinner);


Spinner.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string,
};

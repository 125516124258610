import React from "react";

import TabPanel from "../shared/TabPanel";
import LoginForm from "./LoginForm";
import RequestNewPasswordForm from "./RequestNewPasswordForm";
import {
    Tabs, Tab, AppBar
} from '@material-ui/core/';

import { globalStyles } from '../shared/Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class Login extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            curTab: 0,
        }
    }

    handleTabChange(event, value) {
        this.setState({
            curTab: value,
        })
    }

    render(){

        const {classes} = this.props
        return(
            <>
                
                <AppBar position="static" color="default" className={classes.tabAppBar}>
                    <Tabs
                        value={this.state.curTab}
                        onChange={(event, value) => this.handleTabChange(event, value)}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="Services Tabs"
                        className={classes.smallTabs}
                    >
                        <Tab label="Login" id="login-tab" aria-controls={"tabpanel-0"}  />
                        <Tab label="Vergessen" id="reset-tab" aria-controls={"tabpanel-1"}  />
                    </Tabs>
                </AppBar>
            <TabPanel desc="login-tabs" value={this.state.curTab} index={0}>
                <LoginForm />
            </TabPanel>
            <TabPanel desc="login-tabs" value={this.state.curTab} index={1}>
                <RequestNewPasswordForm />
            </TabPanel>
            </>
        )
    }
}

export default withStyles(globalStyles)(Login)


Login.propTypes = {
    classes: PropTypes.object,
};

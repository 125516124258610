import React from "react";

import axios from "axios";
import setAxiosHeaders from "../shared/AxiosHeaders";
import { Alert, AlertTitle } from '@material-ui/lab';

import { debounce } from "lodash"

import {
    TextField, Typography,  Button, FormControl,
    LinearProgress, Paper,
} from '@material-ui/core/';

import { globalStyles } from '../shared/Theme';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class RegisterForm extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            company_name: null,
            firstname: '',
            lastname: '',
            email: '',
            street: '',
            zip: '',
            zipBlurred: false,
            city: '',
            phone: '',
            phoneValid: false,
            emailHelperText: null,
            emailValid: false,
            emailBlurred: false,
            submitted: false,
            isLoading: false,
            errorMessage: undefined,
            successMessage: undefined,      
        }
        this.checkMailAddr = debounce(this.checkMailAddr, 500)        
        this.formValid = this.formValid.bind(this)
        this.changeHandler = this.changeHandler.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }


    submitForm(event){
        event.preventDefault();
        this.setState({
            isLoading: true,
            submitted: true,
        })
        const user = {
            company: this.state.company,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            street: this.state.street,
            zip: this.state.zip,
            city: this.state.city,
            phone: this.state.phone,
        }
        setAxiosHeaders();
        axios.post('/api/v1/user', {
            user: user
        })
            .then(() => {
                this.setState({
                    successMessage:
                    "Herzlich Willkommen! Die Registrierung war erfolgreich. Bitte überprüfen Sie Ihre E-Mails.",
                    errorMessage: undefined,
                    isLoading: false,
                })
                return
            })
            .catch(error => {
                this.setState({
                    errorMessage: "Die Registrierung konnte aufgrund eines Fehlers nicht abgeschlossen werden: " + error,
                    successMessage: undefined,              
                    isLoading: false,
                })
                return
            })
    }

    checkZIP(val){
        if(5 != val.length){
            return false
        }
        return (!isNaN(val) && !isNaN(parseInt(val)))
    }

    changeHandler(event){
        this.setState({
            [event.target.name]: event.target.value,
        })
        if(event.target.value.trim() === "") {
            this.setState({[event.target.name + "HelperText"]: "Dieses Feld muss ausgefüllt sein."})
            return
        }
        if(event.target.name === 'phone'){
            if(event.target.value.trim().length > 30 || event.target.value.trim().length < 5){
                this.setState({
                    [event.target.name + "HelperText"]: "Telefonnummer muss zwischen 5 und maximal 30 Zeichen lang sein.",
                    phoneValid: false,
                })
                return
            }
                this.setState({
                    [event.target.name + "HelperText"]: undefined,
                    phoneValid: true,
                })
            
            
        }
        if(event.target.name == 'email'){
            this.checkMailAddr(event.target.value)
        }
        if(event.target.name == 'zip'){
            if(false === this.checkZIP(event.target.value)){
                this.setState({
                    zipValid: false, 
                    [event.target.name + "HelperText"]: "Postleitzahl muss aus 5 Zahlen bestehen.",
                })
            }
            else{
                this.setState({
                    zipValid: true, 
                    [event.target.name + "HelperText"]: "",
                })
            }
            return
        }
        this.setState({[event.target.name + "HelperText"]: undefined})
        this.setState({formValid: this.formValid()})
    }

    
    checkMailAddr(mailAddr){
        const user = {
            email: mailAddr,
        }
        setAxiosHeaders();
        axios.post('/api/v1/check_email', {
            user: JSON.stringify(user)
        })
            .then(response => {
                if(response.data.status === "success"){
                    this.setState({
                        emailHelperText: "",
                        emailValid: true,
                    })
                    return true
                }
                if(response.data.status === "error"){
                    if("E-Mail bereits vergeben." === response.data.message){
                        this.setState({
                            emailHelperText: "Sie haben bereits ein Konto bei uns.",
                            emailValid: false,                      
                        })
                        return true
                    }
                    this.setState({
                        emailHelperText: response.data.message,
                        emailValid: false,
                    })
                    return false
                }
                this.setState({
                    emailHelperText: "Interner Fehler.",
                    emailValid: false,                  
                })
                return false
            })
            .catch(error => {
                console.log("error was: " + error)
                return false
            })
    }

    formValid(){
        if(this.state.firstname.trim() &&
           this.state.lastname.trim() &&
           this.state.email &&
           this.state.emailValid && 
           this.state.street.trim() &&
           this.state.zip.trim() &&
           this.state.city.trim() &&
           this.state.phone.trim() &&
           this.state.phoneValid
          )
            
            return true
        return false
    }

    render(){
        
        const {classes} = this.props
        return(
            <>
                <Paper className={classes.paper} id="register-new">
                    <Typography variant="h2" component="h2">
                        Registrieren Sie sich
                    </Typography>
                    { this.state.isLoading && (
                        <LinearProgress />
                    )}

                    { (this.state.successMessage) && (
                        <Alert
                            severity="success">
                            <AlertTitle>Registrierung erfolgreich</AlertTitle>
                            {this.state.successMessage}
                        </ Alert>
                    )}
                    { this.state.errorMessage && (
                        <Alert severity="error">
                            <AlertTitle>Fehler bei der Registrierung</AlertTitle>                       
                            {this.state.errorMessage}
                        </Alert>
                    )}
                    {(!(this.state.successMessage || this.state.isLoading)) && (
                        <FormControl className={classes.form}>
                            <TextField
                                label="Firmenname"
                                className={classes.textField}
                                name="company"
                                invalid={false.toString()}
                                margin="dense"
                                id="register_user_company"
                                fullWidth={true}
                                helperText={this.state.companyHelperText}                         
                                onChange={this.changeHandler}
                            />
                            <TextField
                                label="Vorname"
                                className={classes.textField}
                                name="firstname"
                                required
                                invalid={false.toString()}
                                margin="dense"
                                id="register_user_firstname"
                                fullWidth={true}                            
                                helperText={this.state.firstnameHelperText}                         
                                onChange={this.changeHandler}
                            />
                            <TextField
                                label="Nachname"
                                name="lastname"
                                id="register_user_lastname"
                                className={classes.textField}
                                margin="dense"
                                required
                                fullWidth={true}                            
                                invalid={true.toString()}
                                helperText={this.state.lastnameHelperText}
                                onChange={this.changeHandler}
                            />      
                            <TextField
                                label="E-Mail"
                                name="email"
                                id="register_user_email"
                                onChange={this.changeHandler}
                                className={classes.textField}
                                margin="dense"
                                fullWidth={true}
                                onBlur={() => { this.setState({emailBlurred: true}) }}
                                error={this.state.emailBlurred && false === this.state.emailValid}
                                required
                                helperText={this.state.emailHelperText}
                            />      
                            <TextField
                                label="Straße und Hausnummer"
                                type="text"
                                name="street"
                                id="register_user_street"
                                className={classes.textField}                   
                                onChange={this.changeHandler}
                                helperText={this.state.streetHelperText}                            
                                margin="dense"
                                fullWidth={true}
                                required
                            />      
                            <TextField
                                label="Postleitzahl"
                                type="text"
                                name="zip"
                                id="register_user_zip"
                                className={classes.textField}
                                onBlur={ () => { this.setState({zipBlurred: true}) }}
                                onChange={this.changeHandler}
                                helperText={this.state.zipHelperText}
                                error={!this.state.zipValid && (!!this.state.zipHelperText)}
                                margin="dense"
                                fullWidth={true}                            
                                required
                            />
                            <TextField
                                label="Stadt"
                                type="text"
                                name="city"
                                id="register_user_city"
                                fullWidth={true}                            
                                onChange={this.changeHandler}
                                className={classes.textField}
                                helperText={this.state.cityHelperText}                      
                                margin="dense"
                                required
                            />      
                            <TextField
                                label="Telefonnummer"
                                type="text"
                                name="phone"
                                id="register_user_phone"
                                onChange={this.changeHandler}
                                className={classes.textField}
                                error={!this.state.phoneValid && (!!this.state.phoneHelperText)}
                                helperText={this.state.phoneHelperText}                      
                                margin="dense"
                                fullWidth={true}
                                required
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                id="register_new_user_button"
                                className={classes.textField}                       
                                onClick={this.submitForm}
                                disabled={ (false == this.formValid() || this.state.submitted) }
                            >
                                Registrieren
                            </Button>
                        </FormControl>
                    )}
                </Paper>
                
            </>
        )}
}

export default withStyles(globalStyles)(RegisterForm);

RegisterForm.propTypes = {
    classes: PropTypes.object,    
};
